<template>
  <div class="device-resident">
    <HeaderMenu :key="$route.path" />
    <div class="container-fluid py-4">
      <form @submit.prevent="submitNotify">
        <div class="row">
          <div class="col-md-6">
            <div class="card mb-4">
              <div class="card-body">
                <div class="mb-4">
                  <div class="fw-medium mb-2">{{ $t("__name") }}</div>
                  <div>
                    <input
                      v-if="!notifyModalData.is_template"
                      v-model="notifyModalData.name"
                      class="form-control"
                      type="text"
                      required
                    />
                    <h6 v-else>
                      {{  $t("__" + notifyModalData.name) }}
                    </h6>
                  </div>
                </div>
                <div class="mb-4">
                  <div class="fw-medium mb-2">{{ $t("__notifyMethod") }}</div>
                  <div>
                    <div class="form-check form-check-inline">
                      <input
                        v-model="notifyModalData.has_line"
                        class="form-check-input"
                        type="checkbox"
                        id="has_line"
                        :disabled="!!notifyModalData.is_template"
                      />
                      <label class="form-check-label" for="has_line"
                        >LINE</label
                      >
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        v-model="notifyModalData.has_push"
                        class="form-check-input"
                        type="checkbox"
                        id="has_push"
                        :disabled="!!notifyModalData.is_template"
                      />
                      <label class="form-check-label" for="has_push">{{
                        $t("__push")
                      }}</label>
                    </div>
                  </div>
                  <div v-if="notifyModalData.has_line">
                    <small
                      class="d-block text-danger"
                      v-if="LINEAuth && LINEAuth.length"
                      >{{
                        $t("__LINENotifyAuthorizedQuantity", {
                          qty: LINEAuth.length,
                        })
                      }}</small
                    >
                    <small class="d-block text-danger" v-else>{{
                      $t("__LINENotifyAuthorizeNoAccount")
                    }}</small>
                  </div>
                </div>
                <div class="mb-4">
                  <div class="fw-medium mb-2">{{ $t("__excludeRange") }}</div>
                  <div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="has_exclude"
                        v-model="notifyModalData.has_exclude"
                        :disabled="!!notifyModalData.is_template"
                      />
                      <label class="form-check-label" for="has_exclude">{{
                        $t("__enable")
                      }}</label>
                    </div>
                    <small
                      class="text-danger d-block mt-1"
                      v-if="notifyModalData.has_exclude"
                      >{{ $t("__excludeRangeNote") }}</small
                    >
                    <div v-if="notifyModalData.has_exclude" class="pt-3">
                      <div
                        v-for="(
                          excludeTime, index
                        ) in notifyModalData.exclude_range"
                        :key="'excludeTime' + index"
                        class="row p-3 g-1 align-items-center"
                        :class="{ 'bg-light': index % 2 === 0 }"
                      >
                        <div class="col d-flex align-items-center mt-2 mt-lg-0">
                          <VueCtkDateTimePicker
                            :label="$t('__startTime')"
                            v-model="excludeTime.started_at"
                            only-time
                            format="HH:mm"
                            formatted="HH:mm"
                            right
                            color="#2CBDC0"
                            button-color="#2CBDC0"
                            :id="'excludeTimeStart_at' + index"
                            :disabled="!!notifyModalData.is_template"
                          />
                          <span class="px-1">-</span>
                          <VueCtkDateTimePicker
                            :label="$t('__entTime')"
                            v-model="excludeTime.ended_at"
                            only-time
                            format="HH:mm"
                            formatted="HH:mm"
                            right
                            color="#2CBDC0"
                            button-color="#2CBDC0"
                            :id="'excludeTimeEnd_at' + index"
                            :disabled="!!notifyModalData.is_template"
                          />
                        </div>
                        <div
                          class="col-auto mt-2 mt-lg-0"
                          v-if="!notifyModalData.is_template"
                        >
                          <button
                            type="button"
                            class="btn-close"
                            @click="delExcludeTime(index)"
                          ></button>
                        </div>
                      </div>
                    </div>
                    <div
                      class="text-end mt-2"
                      v-if="!notifyModalData.is_template"
                    >
                      <button
                        type="button"
                        v-if="
                          notifyModalData.exclude_range &&
                          notifyModalData.exclude_range.length < 5 &&
                          notifyModalData.has_exclude
                        "
                        class="btn btn-outline-primary rounded-pill"
                        @click="addExcludeTime"
                      >
                        {{ $t("__addExcludeTime") }}
                      </button>
                    </div>
                  </div>
                </div>
                <div class="mb-4">
                  <div class="fw-medium mb-2">
                    {{ $t("__otherConditions") }}
                  </div>
                  <div>
                    <div
                      class="
                        form-check
                        d-flex
                        align-items-top align-items-sm-center
                        mb-3
                      "
                    >
                      <input
                        class="form-check-input me-2"
                        type="checkbox"
                        id="has_silent_notify"
                        v-model="notifyModalData.has_silent_notify"
                        :disabled="!!notifyModalData.is_template"
                      />
                      <label
                        class="form-check-label align-items-center"
                        for="has_silent_notify"
                      >
                        {{ $t("__afterNotification") }}
                        <span class="d-block d-sm-inline-block">
                          <input
                            :value="
                              secondsToMinutes(
                                notifyModalData.silent_notify_timeout
                              )
                            "
                            @change="
                              notifyModalData.silent_notify_timeout =
                                minutesToSeconds($event.target.value)
                            "
                            type="number"
                            class="
                              form-control
                              d-inline-block
                              mx-sm-2
                              me-2
                              mt-sm-0 mt-2
                            "
                            style="width: 100px"
                            :disabled="!!notifyModalData.is_template"
                          />
                          {{ $t("__withinMinutesWithoutNotification") }}
                        </span>
                      </label>
                    </div>
                    <div
                      class="
                        form-check
                        d-flex
                        align-items-top align-items-sm-center
                        mb-3
                      "
                    >
                      <input
                        class="form-check-input me-2"
                        type="checkbox"
                        id="has_silent_bed"
                        v-model="notifyModalData.has_silent_bed"
                        :disabled="!!notifyModalData.is_template"
                      />
                      <label
                        class="form-check-label align-items-center"
                        for="has_silent_bed"
                      >
                        {{ $t("__backToBed") }}
                        <span class="d-block d-sm-inline-block">
                          <input
                            :value="
                              secondsToMinutes(
                                notifyModalData.silent_bed_timeout
                              )
                            "
                            @change="
                              notifyModalData.silent_bed_timeout =
                                minutesToSeconds($event.target.value)
                            "
                            type="number"
                            class="
                              form-control
                              d-inline-block
                              mx-sm-2
                              me-2
                              mt-sm-0 mt-2
                            "
                            style="width: 100px"
                            :disabled="!!notifyModalData.is_template"
                          />
                          {{ $t("__minutesLaterStartAgain") }}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card mb-4">
              <div class="card-body">
                <div class="fw-medium mb-2">{{ $t("__notify") }}</div>
                <div class="mb-4">
                  <div class="form-check d-flex align-items-center mb-3">
                    <input
                      v-model="notifyModalData.notify_leave"
                      class="form-check-input me-2"
                      type="checkbox"
                      id="notify_leave"
                      :disabled="!!notifyModalData.is_template"
                    />
                    <label class="form-check-label" for="notify_leave">
                      {{ $t("__LVBNotify") }}
                    </label>
                  </div>
                  <div class="bg-light ms-4 p-4">
                    <div class="form-check d-flex align-items-center mb-3">
                      <input
                        v-model="notifyModalData.notify_leave_action"
                        class="form-check-input me-2"
                        type="checkbox"
                        id="notify_leave_action"
                        :disabled="!!notifyModalData.is_template"
                      />
                      <label class="form-check-label" for="notify_leave_action">
                        {{ $t("__leaveTheBed") }}
                      </label>
                    </div>
                    <div class="form-check d-flex align-items-center mb-3">
                      <input
                        v-model="notifyModalData.notify_leave_timeout"
                        class="form-check-input me-2"
                        type="checkbox"
                        id="notify_leave_timeout"
                        checked
                        :disabled="!!notifyModalData.is_template"
                      />
                      <label
                        class="form-check-label"
                        for="notify_leave_timeout"
                      >
                        {{ $t("__leaveTheBedTimeOut") }}
                      </label>
                    </div>
                    <div class="pt-4 mb-3 ms-4">
                      <Slider
                        v-model.number="
                          notifyModalData.notify_leave_timeout_max
                        "
                        :min="60"
                        :max="18000"
                        :format="SliderFormatMinuteMore"
                        :step="60"
                        :disabled="!!notifyModalData.is_template"
                      />
                    </div>
                    <!-- <div
                      class="form-check d-flex align-items-center mb-3"
                    >
                      <input
                        class="form-check-input me-2"
                        type="checkbox"
                        id="notify_leave_timeout_max"
                        v-model="
                          notifyModalData
                            .notify_leave_site
                        "
                        :disabled="!!notifyModalData.is_template"
                      />
                      <label
                        class="form-check-label"
                        for="notify_leave_timeout_max"
                      >
                        {{ $t("__SIT") }}
                      </label>
                    </div>
                    <div class="pt-4 mb-3 ms-4">
                      <Slider
                        v-model.number="
                          notifyModalData
                            .notify_leave_site_max
                        "
                        :min="1"
                        :max="600"
                        :format="SliderFormatSecondLast"
                        :disabled="!!notifyModalData.is_template"
                      />
                    </div> -->
                  </div>
                </div>
                <div class="mb-4">
                  <div class="form-check d-flex align-items-center mb-3">
                    <input
                      v-model="notifyModalData.notify_lying"
                      class="form-check-input me-2"
                      type="checkbox"
                      id="notify_leave"
                      :disabled="!!notifyModalData.is_template"
                    />
                    <label class="form-check-label" for="notify_leave">
                      {{ $t("__LYBNotify") }}
                    </label>
                  </div>
                  <div class="bg-light ms-4 p-4">
                    <label class="form-check-label" for="">
                      {{ $t("__LYBTime") }}
                    </label>
                    <div class="pt-4 mt-3">
                      <Slider
                        v-model.number="notifyModalData.notify_lying_max"
                        :min="3600"
                        :max="21600"
                        :format="SliderFormatMinuteMore"
                        :step="60"
                        :disabled="!!notifyModalData.is_template"
                      />
                    </div>
                  </div>
                </div>
                <div class="mb-4">
                  <div class="form-check d-flex align-items-center mb-3">
                    <input
                      v-model="notifyModalData.notify_respiration"
                      class="form-check-input me-2"
                      type="checkbox"
                      id="notify_leave"
                      :disabled="!!notifyModalData.is_template"
                    />
                    <label class="form-check-label" for="notify_leave">
                      {{ $t("__RRNotify") }}
                    </label>
                  </div>
                  <div class="bg-light ms-4 p-4">
                    <label class="form-check-label">
                      {{ $t("__rrValue") }}
                    </label>
                    <div class="pt-4 mt-3">
                      <Slider
                        :value="sliderNotifyRespiration"
                        @update="changeSliderNotifyRespiration"
                        :min="1"
                        :max="45"
                        :disabled="!!notifyModalData.is_template"
                      />
                    </div>
                    <label class="form-check-label mt-3">
                      {{ $t("__lastTime") }}
                    </label>
                    <div class="pt-4 mt-3">
                      <Slider
                        v-model.number="
                          notifyModalData.notify_respiration_duration
                        "
                        :min="60"
                        :max="7200"
                        :format="SliderFormatMinute"
                        :step="60"
                        :disabled="!!notifyModalData.is_template"
                      />
                    </div>
                  </div>
                </div>
                <!-- <div class="mb-4">
                  <div
                    class="form-check d-flex align-items-center mb-3"
                  >
                    <input
                      v-model="
                        notifyModalData.notify_restless
                      "
                      class="form-check-input me-2"
                      type="checkbox"
                      id="notify_leave"
                      :disabled="!!notifyModalData.is_template"
                    />
                    <label
                      class="form-check-label"
                      for="notify_leave"
                    >
                      {{ $t("__restlessNotify") }}
                    </label>
                  </div>
                  <div class="bg-light ms-4 p-4">
                    <label class="form-check-label">
                      {{ $t("__restlessnessValue") }}
                    </label>
                    <div class="pt-4 mt-3">
                      <Slider
                        v-model.number="
                          notifyModalData
                            .notify_restless_max
                        "
                        :min="0"
                        :max="100"
                        :format="SliderFormatMore"
                        :disabled="!!notifyModalData.is_template"
                      />
                    </div>
                    <label class="form-check-label mt-3">
                      {{ $t("__lastTime") }}
                    </label>
                    <div class="pt-4 mt-3">
                      <Slider
                        v-model.number="
                          notifyModalData
                            .notify_restless_duration
                        "
                        :min="60"
                        :max="7200"
                        :format="SliderFormatMinute"
                        :step="60"
                        :disabled="!!notifyModalData.is_template"
                      />
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div
          class="d-flex flex-wrap justify-content-between"
          v-if="!notifyModalData.is_template"
        >
          <button
            v-if="
              !notifyModalData.devices_count &&
              $route.params.id !== 'add-notify-template'
            "
            type="button"
            class="mb-2 btn btn-outline-danger rounded-pill"
            @click="deleteAlert"
          >
            {{ $t("__delete") }}
          </button>
          <div class="ms-auto">
            <button
              type="button"
              class="mb-2 btn btn-outline-primary rounded-pill"
              @click="cancelNotify"
            >
              {{ $t("__cancel") }}
            </button>
            <button
              type="submit"
              class="ms-2 mb-2 btn btn-primary rounded-pill"
            >
              {{ $t("__save") }}
            </button>
          </div>
        </div>
      </form>
    </div>
    <div
      class="modal fade"
      id="deleteModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      ref="deleteModal"
    >
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header justify-content-center">
            <h5 class="modal-title">{{ $t("__templateDelete") }}</h5>
          </div>
          <div class="modal-body text-center">
            <p class="mb-0">{{ $t("__templateDeleteInfo") }}</p>
            <p class="mb-0 fs-5 py-2 fw-medium">
              【{{ notifyModalData.name }}】
            </p>
            <p class="mb-0">{{ $t("__template") }}</p>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-outline-secondary rounded-pill"
              data-bs-dismiss="modal"
            >
              {{ $t("__cancel") }}
            </button>
            <button
              @click="deleteNotify"
              type="button"
              class="btn btn-primary rounded-pill"
            >
              {{ $t("__confirm") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Slider from '@vueform/slider/dist/slider.vue2.js'
import '@vueform/slider/themes/default.css'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import HeaderMenu from '@/components/layout/HeaderMenu.vue'
import { notifyTemplate } from '@/http/api/notifyTemplate.js'
import i18n from '@/lang/lang.js'
import { mapState, mapMutations } from 'vuex'
import { Modal } from 'bootstrap'
import { line } from '@/http/api/line.js'

export default {
  name: 'NotifyTemplate',
  components: { HeaderMenu, VueCtkDateTimePicker, Slider },
  data () {
    return {
      notifyModalData: {
        name: '',
        has_line: 0,
        has_push: 0,
        has_exclude: 0,
        exclude_range: [],
        has_silent_bed: 0,
        silent_bed_timeout: 0,
        has_silent_notify: 0,
        silent_notify_timeout: 0,
        notify_leave: 0,
        notify_leave_action: 0,
        notify_leave_timeout: 0,
        notify_leave_timeout_max: 60,
        notify_leave_site: 0,
        notify_leave_site_max: 1,
        notify_lying: 0,
        notify_lying_max: 3600,
        notify_restless: 0,
        notify_restless_max: 0,
        notify_restless_duration: 60,
        notify_respiration: 0,
        notify_respiration_min: 1,
        notify_respiration_max: 45,
        notify_respiration_duration: 60,
        LINEAuth: null
      },
      SliderFormatMore: (val) => {
        return i18n.t('__moreThanThe') + Math.round(val)
      },
      SliderFormatMinuteMore: (val) => {
        return (
          i18n.t('__moreThanThe') + Math.round(val / 60) + i18n.t('__minute')
        )
      },
      SliderFormatMinuteLess: (val) => {
        return i18n.t('__lessThan') + Math.round(val / 60) + i18n.t('__minute')
      },
      SliderFormatMinute: (val) => {
        return Math.round(val / 60) + i18n.t('__minute')
      },
      SliderFormatSecond: (val) => {
        return Math.round(val) + i18n.t('__second')
      },
      SliderFormatSecondLast: (val) => {
        return i18n.t('__last') + Math.round(val) + i18n.t('__second')
      }
    }
  },
  computed: {
    ...mapState(['token', 'userInfo']),
    sliderNotifyRespiration () {
      return [
        this.notifyModalData.notify_respiration_min,
        this.notifyModalData.notify_respiration_max
      ]
    }
  },
  watch: {
    userInfo () {
      this.getNotifyTemplate()
      this.getLINEAuth()
    },
    $route (to, from) {
      this.getNotifyTemplate()
      this.getLINEAuth()
    }
  },
  methods: {
    ...mapMutations([
      'LoadingText',
      'Loading',
      'Loaded',
      'AlertsInfo',
      'Alerted'
    ]),
    getLINEAuth () {
      const vm = this
      line.lineAuth(vm.token).then((res) => {
        if (res.status <= 201 && res.data.status === 'success') {
          vm.LINEAuth = res.data.data
        } else {
          vm.Loaded()
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: res.data.errors.toString()
          })
          vm.Alerted()
        }
      })
    },
    changeSliderNotifyRespiration (vale) {
      this.notifyModalData.notify_respiration_min = vale[0]
      this.notifyModalData.notify_respiration_max = vale[1]
    },
    resetDate () {
      this.notifyModalData = {
        name: '',
        has_line: 0,
        has_push: 0,
        has_exclude: 0,
        exclude_range: [],
        has_silent_bed: 0,
        silent_bed_timeout: 0,
        has_silent_notify: 0,
        silent_notify_timeout: 0,
        notify_leave: 0,
        notify_leave_action: 0,
        notify_leave_timeout: 0,
        notify_leave_timeout_max: 60,
        notify_leave_site: 0,
        notify_leave_site_max: 1,
        notify_lying: 0,
        notify_lying_max: 3600,
        notify_restless: 0,
        notify_restless_max: 0,
        notify_restless_duration: 60,
        notify_respiration: 0,
        notify_respiration_min: 1,
        notify_respiration_max: 45,
        notify_respiration_duration: 60
      }
    },
    getNotifyTemplate () {
      const vm = this
      this.resetDate()
      if (vm.$route.params.id === 'add-notify-template') return
      vm.Loading()
      notifyTemplate.get(vm.$route.params.id, vm.token).then((res) => {
        if (res.status <= 201) {
          const data = res.data.data
          vm.notifyModalData = data
        } else {
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: res.data.errors.toString()
          })
          vm.Alerted()
        }
        vm.Loaded()
      })
    },
    minutesToSeconds (time) {
      return time * 60
    },
    secondsToMinutes (time) {
      return time / 60
    },
    delExcludeTime (index) {
      this.notifyModalData.exclude_range.splice(index, 1)
    },
    addExcludeTime () {
      this.notifyModalData.exclude_range.push({
        started_at: null,
        ended_at: null
      })
    },
    deleteAlert () {
      const vm = this
      vm.bootstrap_modal = new Modal(vm.$refs.deleteModal)
      vm.bootstrap_modal.show()
    },
    deleteNotify () {
      const vm = this
      vm.bootstrap_modal.hide()
      notifyTemplate.delete(vm.notifyModalData.id, vm.token).then((res) => {
        if (res.status <= 201 && res.data.status === 'success') {
          vm.AlertsInfo({
            state: 'success',
            title: i18n.t('__success'),
            info: i18n.t('__deleteSuccess')
          })
          vm.Alerted()
          vm.$router.back(-1)
        } else {
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: res.data.errMsg.toString()
          })
          vm.Alerted()
        }
        vm.Loaded()
      })
    },
    cancelNotify () {
      const vm = this
      if (vm.$route.params.id === 'add-notify-template') {
        vm.$router.back(-1)
      } else {
        vm.getNotifyTemplate()
      }
    },
    submitNotify () {
      const vm = this
      if (vm.$route.params.id === 'add-notify-template') {
        notifyTemplate.create(vm.notifyModalData, vm.token).then((res) => {
          if (res.status <= 201 && res.data.status === 'success') {
            vm.AlertsInfo({
              state: 'success',
              title: i18n.t('__success'),
              info: i18n.t('__editSuccess')
            })
            vm.Alerted()
            this.$router.push({
              name: 'NotifyTemplate',
              params: { id: res.data.id }
            })
          } else {
            vm.AlertsInfo({
              state: 'error',
              title: i18n.t('__error'),
              info: res.data.errMsg.toString()
            })
            vm.Alerted()
          }
          vm.Loaded()
        })
      } else {
        notifyTemplate
          .update(vm.notifyModalData.id, vm.notifyModalData, vm.token)
          .then((res) => {
            if (res.status <= 201 && res.data.status === 'success') {
              vm.getNotifyTemplate()
              vm.AlertsInfo({
                state: 'success',
                title: i18n.t('__success'),
                info: i18n.t('__editSuccess')
              })
              vm.Alerted()
            } else {
              vm.AlertsInfo({
                state: 'error',
                title: i18n.t('__error'),
                info: res.data.errMsg.toString()
              })
              vm.Alerted()
            }
            vm.Loaded()
          })
      }
    }
  },
  created () {},
  mounted () {
    if (this.userInfo) {
      this.getNotifyTemplate()
      this.getLINEAuth()
    }
  }
}
</script>

<style  lang="scss">
.slider-connect {
  background: $primary;
}
.slider-tooltip {
  border: 1px solid $dark;
  background: $dark;
}
</style>
